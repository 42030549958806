<template>
  <div>
    <Row :gutter="24">
      <Col :span="5">
        <Card>
          <p slot="title">客户档案信息审核</p>
          <!-- <Tag  color="blue">{{dataobj.ProcessStatusDesc}}</Tag> -->
          <!-- <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 30px;"
            type="success"
            @click="DoPass()"
            icon="md-cloud-upload"
          >通过</Button>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="warning"
            @click="NotPass()"
            icon="md-remove-circle"
          >不通过</Button>
          <Button
            v-if="agentId==1||agentId==5"
            slot="extra"
            style="margin-left: 10px;"
            type="info"
            @click="ViewVR()"            
          >全景图</Button> -->
          <Button
            slot="extra"
            style="margin-left: 30px;"
            type="ifno"
            @click="GoBack()"
            icon="md-arrow-back"
          >返回</Button>

          <!-- <div v-if="dataobj.flag == true"><p>电子签名: </p><Button
            
            style="margin-left: 30px;"
            type="ifno"
            @click="ViewSignImgs()"
          >查看</Button></div> -->
          <div v-if="dataobj.flag == true"><p>电子签名: </p>
            <!-- <div class="img-list">
              <div
                v-for="(item, index) in siimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div> -->
            <div class="img-list"
                v-bind:style="{'background-image':'url(' + siimgurl+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','width': '100%','height': '75px'}">
            </div>
          </div>
          <div><Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 30px;"
            type="success"
            @click="DoPass()"
            icon="md-cloud-upload"
          >通过</Button>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="warning"
            @click="NotPass()"
            icon="md-remove-circle"
          >不通过</Button>
          <Button
            v-if="agentId==1||agentId==5"
            slot="extra"
            style="margin-left: 10px;"
            type="info"
            @click="ViewVR()"            
          >全景图</Button>
          
          </div>
          <p>质保卡号: {{dataobj.WarrantyNum}}</p>
          <p>客户姓名: {{dataobj.CustName}}</p>
          <p>客户电话: {{dataobj.CustTel}}</p>
          <p>详细地址: {{dataobj.CustAddress}}</p>
          <!-- <div style="font-weight:bold;"><p>异常问题: {{dataobj.ProblemsName}} </p></div> -->
          <div style="font-weight:bold;"><p>厨房: {{dataobj.KitchenNum}} 个</p></div>
          <div style="font-weight:bold;"><p>卫生间: {{dataobj.WashNum}} 个</p></div>
          <!-- <p>阀门品牌: {{dataobj.Brand}}</p> -->
          <div style="font-weight:bold;"><p>户型: {{dataobj.HouseTypeName}}</p></div>
          <div style="font-weight:bold;"><p>户型满足条件: {{dataobj.ConditionName}}</p></div>
          <div style="font-weight:bold;"><p>详细类型: {{dataobj.DetailType}}</p></div>
          <div style="font-weight:bold;"><p>管道米数: {{dataobj.PipeLength}}</p></div>
          <div style="font-weight:bold;"><p>管道类型: {{dataobj.PipeLineName}}</p></div>
          <div style="font-weight:bold;"><p>是否走顶回水: {{dataobj.InstallTypeDesc}}</p></div>
          <div style="font-weight:bold;"><p>是否二次试压: {{dataobj.IsSecondPre=="1"?"是":"否"}}</p></div>
          <div style="font-weight:bold;"><p>是否远程: {{dataobj.IsDistant=="1" ? "是" : "否"}}</p></div>
          <div style="font-weight:bold;"><p>是否上传业主群推广图片: {{dataobj.IsPopularize=="1" ? "是" : "否"}}</p></div>
          <div style="font-weight:bold;"><p>是否上传朋友圈推广图片: {{dataobj.IsWechat=="1" ? "是" : "否"}}</p></div>
          <div style="font-weight:bold;"><p>朋友圈数量: {{dataobj.WechatNum}}</p></div>
          <div style="font-weight:bold;"><p>是否上传VR全景图片: {{dataobj.IsUploadVR=="1" ? "是" : "否"}}</p></div>
          <div style="font-weight:bold;"><p>预约单备注: {{ dataobj.PreRemark }}</p></div>
          <!-- <div style="font-weight:bold;"><p>是否赠送促销活动产品: {{dataobj.IsGiveProduct=="1" ? "是" : "否"}}</p></div> -->
          <div style="font-weight:bold;"><p>是否使用全套大流量系统: {{dataobj.IsBigFlow=="1"?"是":"否"}}</p></div>
          <div style="font-weight:bold;"><p>是否家装单: {{dataobj.IsCompany=="1"?"是":"否"}}</p></div>
          <div style="font-weight:bold;"><p>安装不规范备注: {{dataobj.StandardDesc}}</p></div>
          <!-- <div style="font-weight:bold;"><p>中奖奖品: {{dataobj.PrizeName}}</p></div> -->
          <div style="font-weight:bold;"><p>不锈钢水管备注: {{dataobj.PipeRemark}}</p></div>
          <p>试压类型: {{dataobj.PressureTypeName}}</p>
          <p>备注: {{dataobj.Remark}}</p>
          <p>家装公司姓名: {{dataobj.CompanyName}}</p>
          <p>预约经销商: {{dataobj.DealerName}}</p>
          <!-- <div v-if="dataobj.Company>0"><p>预约经销商: {{dataobj.PreDealerName}}({{dataobj.DealerCorp}})</p></div>
          <div v-else><p>预约经销商: {{dataobj.PreDealerName}}</p></div> -->
          <div v-if="dataobj.Company>0">
            <div v-if="dataobj.ChildCompanyName!=null&&dataobj.ChildCompanyName!=''">
              <p>预约装饰公司: {{dataobj.DealerCorp}}【{{dataobj.ChildCompanyName}}】</p>
            </div>
            <div v-else>
              <p>预约装饰公司: {{dataobj.DealerCorp}}</p>
            </div>
          </div>
          <p>归属经销商: {{dataobj.DealerName}}</p>
          <p>归属经销商原因: {{dataobj.Remark1}}</p>
          <p>试压员: {{dataobj.WorkerName}}</p>
          <p>试压开始时间: {{dataobj.StartGuaranteedTime}}</p>
          <p>加压前压力: {{dataobj.PrePressure}} 公斤</p>
          <p>试压结束时间: {{dataobj.EndGuaranteedTime}}</p>
          <p>加压后压力: {{dataobj.AftPressure}} 公斤</p>
          <p>不合格原因: {{dataobj.UnReason}}</p>
          <!-- <div v-if="dataobj.WarrantyNum.indexOf('W') == 0||dataobj.WarrantyNum.indexOf('R') == 0"><p>电子签名: </p><Button
            
            style="margin-left: 30px;"
            type="ifno"
            @click="ViewSignImgs()"
          >查看</Button></div> -->
          <!-- <div v-if="dataobj.flag == true"><p>电子签名: </p><Button
            
            style="margin-left: 30px;"
            type="ifno"
            @click="ViewSignImgs()"
          >查看</Button></div> -->
        </Card>
      </Col>
      <Col :span="4">
        <Card v-if="acount>=1" style="color:red;">
          <p slot="title">积分对象信息</p>
          <p>现金积分人: {{integralobj.Name}}</p>
          <p>现金积分人电话: {{integralobj.Tel}}</p>
          <p>现金积分: {{integralobj.Integral}}</p>
        </Card>
        <Card v-else>
          <p slot="title">积分对象信息</p>
          <p>现金积分人: {{integralobj.Name}}</p>
          <p>现金积分人电话: {{integralobj.Tel}}</p>
          <p>现金积分: {{integralobj.Integral}}</p>
        </Card>
        <!-- <Card>
          <p slot="title">积分对象信息</p>
          <p>现金积分人: {{integralobj.Name}}</p>
          <p>现金积分人电话: {{integralobj.Tel}}</p>
          <p>物资积分人: {{integralobj.MatName}}</p>
          <p>物资积分人电话: {{integralobj.MatTel}}</p>
          <p>现金积分: {{integralobj.Integral}}</p>
          <p>物资积分: {{integralobj.MatIntegral}}</p>
        </Card> -->
        <Card style="margin-top:5px;">
          <p slot="title">审核信息</p>
          <div style="font-weight:bold;"><p>过滤器数量: {{dataobj.PreFilterNum}}</p></div>
          <div style="font-weight:bold;"><p>过滤器类型: {{dataobj.PreFilterName}}</p></div>
          <div style="font-weight:bold;word-wrap: break-word;word-break: break-all">过滤器编码: {{dataobj.PreFilterCode}}</div>
          <div style="font-weight:bold;"><p>大流量新品数量: {{dataobj.BigFlowNum}}</p></div>
          <div style="font-weight:bold;"><p>大流量新品备注: {{dataobj.BigFlowRemark}}</p></div>
          <div style="font-weight:bold;"><p>暗装花洒数量: {{dataobj.ConShowerNum}}</p></div>
          <div style="font-weight:bold;word-wrap: break-word;word-break: break-all"><p>暗装花洒编码: {{dataobj.ConShowerCode}}</p></div>
          <div style="font-weight:bold;"><p>中央净水数量: {{dataobj.CentrePWNum}}</p></div>
          <div style="font-weight:bold;"><p>中央净水编码: {{dataobj.CentrePWCode}}</p></div>
          <div style="font-weight:bold;"><p>止回阀数量: {{dataobj.CheckValueNum}}</p></div>
          <p>其他积分: {{dataobj.OtherIntegral}}</p>
          <p>其他积分备注: {{dataobj.OtherIntegralRemark}}</p>
          <div style="font-weight:bold;"><p>丝口: {{dataobj.ScrewNum}}</p></div>
          <div style="font-weight:bold;"><p>配套产品: {{dataobj.OtherProductDesc}}</p></div>
          <div style="font-weight:bold;"><p>使用物料: {{dataobj.UseMaterialName}}</p></div>
          <div style="font-weight:bold;"><p>经销商录单家装公司是否与现场一致: {{dataobj.IsCompanySame=="1" ? "是" : "否"}}</p></div>
          <div style="font-weight:bold;"><p>家装公司名称: {{dataobj.DlCompanyName}}</p></div>
          <!-- <div style="font-weight:bold;"><p>安全阀泄压阀温馨提示: {{dataobj.IsTips=="1" ? "是" : "否"}}</p></div> -->
        </Card>
      </Col>
      <Col :span="4" v-if="agentId==1 && dataobj.Company>0 && isCheck==0">
      <Card>
          <p slot="title">家装单核实信息</p>
          <Form :model="postdata">
            <FormItem>
              <div>1、经销商录单家装公司是否与现场一致<span style="color:red;">*</span></div>
              <RadioGroup v-model="postdata.IsCompanySame" vertical>
                <Radio label="0">
                    <span>否</span>
                </Radio>
                <Radio label="1">
                    <span>是</span>
                </Radio>
              </RadioGroup>
            </FormItem>
            <FormItem>
              <div>2、核实人/原因</div>
              <Input v-model="postdata.Checker" style="width:100%;" placeholder="请填写核实人/原因"></Input>
            </FormItem>
            <FormItem style="text-align:center;">
              <Button type="primary" @click="SubmitData" long style="width:100%;height:35px;">提交</Button>
            </FormItem>
          </Form>
      </Card>
      </Col>
      <Col :span="11">
        <Tabs type="card" v-if="agentId==1" @on-click="handleClick" name="img">
          <TabPane label="全部管路图" name="all" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurls" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <!-- <TabPane label="卫生间" name="wash" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in waimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="厨房" name="kitchen" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in kiimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="客厅" name="parlour" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in paimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="阳台" name="balcony" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in baimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane> -->
          <TabPane label="查询结果" name="field1" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in fiimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <!-- <TabPane label="其他" name="" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane> -->
          <TabPane label="安装不规范" name="standard" tab="img">
            <!-- <div style="margin-left:5px;">安装不规范备注: {{StandardDesc}}</div> -->
            <div class="img-list">
              <div
                v-for="(item, index) in stimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="活动图片" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in actimgurl" @click="viewactimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <TabPane label="全景图片" name="VRImgs" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in VRImgsUrl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <TabPane label="开单凭证" name="billimgs" tab="img" v-if="dataobj.AgentId==1&&dataobj.PipelineType==126">
            <Tabs type="card" @on-click="handle2Click" name="bill">
              <TabPane label="系统开单" name="system" tab="bill">
                <van-collapse v-model="activeNames" @change="onChange" v-if="MaterialList.length>0" style="width:60%;">
                  <van-collapse-item title="材料清单" name="1" value="点击展开明细">
                    <div class="list" v-for="item in MaterialList" v-bind:key="item.ID">
                      <div>
                        <van-image lazy-load width="45" height="45" fit="contain" :src="item.ImgUrl" />
                      </div>
                      <div class="text-display-detail">
                        <div class="text-title">{{item.MaterialName}}</div>
                        <div class="text-tips">规格：{{item.MaterialSpecName}}</div>
                      </div>
                      <div class="step">
                        <div>{{item.FacePrice}}×{{item.MaterialNum}}({{item.MaterialUnitName}})</div>
                      </div>
                    </div>
                    <van-cell-group>
                      <van-field label="开单金额" v-model="PreSaleEX.BillAmount" :border="false" readonly />
                    </van-cell-group>
                  </van-collapse-item>
                </van-collapse>
              </TabPane>
              <TabPane label="上传图片" name="upload" tab="bill">
                <div class="img-list" v-if="imgurl.length>0">
                  <div
                    v-for="(item, index) in BillImgsUrl" @click="viewimg(item,1)" :key="index" class="img-cell"
                    v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                    <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                    <van-icon name="close" />
                    </span>-->
                  </div>
                </div>
              </TabPane>
            </Tabs>
            <!-- <van-collapse v-model="activeNames" @change="onChange" v-if="billway == 1" style="width:100%;">
              <van-collapse-item title="材料清单" name="1" value="点击展开明细">
                <div class="list" v-for="item in MaterialList" v-bind:key="item.ID">
                  <div>
                    <van-image lazy-load width="45" height="45" fit="contain" :src="item.ImgUrl" />
                  </div>
                  <div class="text-display-detail">
                    <div class="text-title">{{item.MaterialName}}</div>
                    <div class="text-tips">规格：{{item.MaterialSpecName}}</div>
                  </div>
                  <div class="step">
                    <div>{{item.FacePrice}}×{{item.MaterialNum}}({{item.MaterialUnitName}})</div>
                  </div>
                </div>
                <van-cell-group>
                  <van-field label="开单金额" v-model="PreSaleEX.BillAmount" :border="false" readonly />
                </van-cell-group>
              </van-collapse-item>
            </van-collapse>
            <div class="img-list" v-if="billway == 2">
              <div
                v-for="(item, index) in BillImgsUrl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div> -->
          </TabPane>
          <!-- <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 30px;"
            type="ifno"
            @click="WarnAuditLogList(dataobj)"
          >预警审核记录</Button> -->
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="success"
            :disabled="dis"
            @click="PipeCheck(1)"
            icon="md-cloud-upload"
          >合格</Button>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="warning"
            :disabled="dis"
            @click="PipeUnCheck(-1)"
            icon="md-remove-circle"
          >不合格</Button>
        </Tabs>
        <Tabs type="card" v-else-if="agentId==5" @on-click="handleClick" name="img">
        <!-- <Tabs type="card" v-if="agentId!=1"> -->
          <TabPane label="现场图片" name="all" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurls" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <TabPane label="查询结果" name="field1" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in fiimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="安装不规范" name="standard" tab="img">
            <!-- <div style="margin-left:5px;">安装不规范备注: {{StandardDesc}}</div> -->
            <div class="img-list">
              <div
                v-for="(item, index) in stimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="活动图片" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in actimgurl" @click="viewactimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <TabPane label="全景图片" name="VRImgs" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in VRImgsUrl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="success"
            :disabled="dis"
            @click="PipeCheck(1)"
            icon="md-cloud-upload"
          >合格</Button>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="warning"
            :disabled="dis"
            @click="PipeUnCheck(-1)"
            icon="md-remove-circle"
          >不合格</Button>
        </Tabs>
        <Tabs type="card" v-else @on-click="handleClick" name="img">
          <TabPane label="现场图片" name="all" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="活动图片" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in actimgurl" @click="viewactimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="全景图片" name="VRImgs" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in VRImgsUrl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="success"
            :disabled="dis"
            @click="PipeCheck(1)"
            icon="md-cloud-upload"
          >合格</Button>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="warning"
            :disabled="dis"
            @click="PipeUnCheck(-1)"
            icon="md-remove-circle"
          >不合格</Button>
        </Tabs>
      </Col>
    </Row>
    <Modal v-model="imgviewmodal" width="880px" draggable="true">
      <p slot="header" style="text-align:center;">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;" v-if="agentId==1">
        <div @click="preImgNew()" @keyup.left="preImgNew">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;object-fit: contain;" :src="currentImgurl" alt />
        <!-- <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" id="guanlutu" alt /> -->
        <div @click="nextImgNew()" @keyup.right="nextImgNew">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div style="text-align:center;display: flex;align-items: center;" v-if="agentId!=1">
        <div @click="preImg()" @keyup.left="preImg">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;object-fit: contain;" :src="currentImgurl" alt />
        <!-- <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" id="guanlutu" alt /> -->
        <div @click="nextImg()" @keyup.right="nextImg">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div slot="footer">
          <Button type="info" @click="viewOriginImg">查看原图</Button>
          <Button @click="close">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="actimgviewmodal" width="880px" draggable="true">
      <p slot="header" style="text-align:center;">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preActImg()" @keyup.left="preActImg">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;object-fit: contain;" :src="currentActImgurl" alt />
        <div @click="nextActImg()" @keyup.right="nextActImg">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div slot="footer">
          <Button type="info" @click="viewActOriginImg">查看原图</Button>
          <Button @click="close">关闭</Button>
      </div>
    </Modal>

    <Modal v-model="VRViewModal" width="450" style="padding:0px" draggable="true">
      <p slot="header" style="text-align:center;">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>VR全景图</span>
      </p>
      <iframe :src="VRViewModalUrl" style="height:500px;width:420px;" frameborder="0"></iframe>
    </Modal>
    <Modal title="管路图不合格" width="600" height="800" v-model="unreasonmodal.show" @on-ok="UnReason_ModalOK" @on-cancel="ModalCancel">
      <Input v-model="unreasonmodal.unreason" :number="unreasonmodal.show" type="textarea" style="width:570px" :autosize="{minRows: 3,maxRows: 10}" placeholder="请填写不合格原因"></Input>
    </Modal>
    <Modal title="审核通过" width="600" height="800" v-model="auditmodal.show" @on-ok="AuditRemark_ModalOK" @on-cancel="ModalCancel">
      <Input v-model="auditmodal.auditremark" :number="auditmodal.show" type="textarea" style="width:570px" :autosize="{minRows: 3,maxRows: 10}" placeholder="请填写审核通过备注"></Input>
    </Modal>
  </div>
</template>

<script>
import { CellGroup, Collapse, CollapseItem, Field, Icon, Image } from 'vant';
export default {
  inject:['reload'],
  data() {
    return {
      // xuanzhuanNumCar: 0,
      unreasonmodal: {
        show: false,
        unreason: "",
        customerid: ""
      },
      auditmodal: {
        show: false,
        auditremark: "",
        customerid: ""
      },
      warnlogmodal: {
        show: false
      },
      postdata: {
        CustomerID: 0,
        IsCompanySame: 0,
        Checker: ""
      },
      isCheck:0,
      wcount:0,
      acount:0,
      scount:0,
      ccount:0,
      dcount:0,
      dataobj: {},
      integralobj: {},
      submitloading: false,
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      dis: false,
      ischecks: "",
      actimgurl: [],
      actimgviewmodal: false,
      currentActImgurl: "",
      waimgurl: [],
      kiimgurl: [],
      paimgurl: [],
      baimgurl: [],
      stimgurl: [],
      fiimgurl: [],
      VRImgsUrl:[],
      BillImgsUrl:[],
      siimgurl: [],
      StandardDesc: "",
      wrimgurl: [],
      agentId: 0,
      active: "all",
      imgurls: [],
      activeNames: [''],
      MaterialList:[],
      PreSaleEX:'',
      billway:0,
      base_url: this.$file.url,
      VRViewModal:false  ,
      VRViewModalUrl:'',
      active2: "system",
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    viewimg(item) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    viewactimg(item) {
      this.actimgviewmodal = true;
      this.currentActImgurl = item;      
    },
    ViewVR(){
      this.VRViewModal=true;      
      
      this.VRViewModalUrl= "https://"+document.domain+"/#/vrview/"+this.dataobj.WarrantyNum;

    },
    ViewSignImgs(){
      var that=this;
      var id =this.$route.params.id;
      var warranty = that.dataobj.WarrantyType.split(',');
      if(warranty.length == 2){
        // that.$router.push({
        //   name: "customerWarranty_Red",
        //   params: { id: id },
        // });
        var RedUrl = "https://"+document.domain+"/#/customerWarranty_Red/"+id;
        window.open(RedUrl);
      }else{
        // that.$router.push({
        //   name: "customerWarranty_White",
        //   params: { id: id },
        // });
        var WhiteUrl = "https://"+document.domain+"/#/customerWarranty_White/"+id;
        window.open(WhiteUrl);
      }
      // 双重质保
      // if (that.dataobj.WarrantyNum.indexOf("R") == 0) {        
      //   that.$router.push({
      //     name: "customerWarranty_Red",
      //     params: { id: id },
      //   });
      // }
      // // 产品质保
      // if (that.dataobj.WarrantyNum.indexOf("W") == 0) {
      //   // path='/pages/presale/previewwhite/previewwhite';
      //   that.$router.push({
      //     name: "customerWarranty_White",
      //     params: { id: id },
      //   });
      // }
    },
    preImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.imgurl[this.imgurl.length - 1];
      } else {
        nextImgUrl = this.imgurl[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.imgurl.length - 1) {
        nextImgUrl = this.imgurl[index + 1];
      } else {
        nextImgUrl = this.imgurl[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    preActImg() {
      var index = this.actimgurl.indexOf(this.currentActImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.actimgurl[this.actimgurl.length - 1];
      } else {
        nextImgUrl = this.actimgurl[index - 1];
      }
      this.currentActImgurl = nextImgUrl;
    },
    nextActImg() {
      var index = this.actimgurl.indexOf(this.currentActImgurl);
      var nextImgUrl = "";
      if (index < this.actimgurl.length - 1) {
        nextImgUrl = this.actimgurl[index + 1];
      } else {
        nextImgUrl = this.actimgurl[0];
      }
      this.currentActImgurl = nextImgUrl;
    },
    preImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex == 0) {
            anextImgUrl = this.imgurls[this.imgurls.length - 1];
          } else {
            anextImgUrl = this.imgurls[aindex - 1];
          }
          this.currentImgurl = anextImgUrl;
          break;
        // case "wash":
        //   var windex = this.waimgurl.indexOf(this.currentImgurl);
        //   var wnextImgUrl = "";
        //   if (windex == 0) {
        //     wnextImgUrl = this.waimgurl[this.waimgurl.length - 1];
        //   } else {
        //     wnextImgUrl = this.waimgurl[windex - 1];
        //   }
        //   this.currentImgurl = wnextImgUrl;
        //   break;
        // case "kitchen":
        //   var kindex = this.kiimgurl.indexOf(this.currentImgurl);
        //   var knextImgUrl = "";
        //   if (kindex == 0) {
        //     knextImgUrl = this.kiimgurl[this.kiimgurl.length - 1];
        //   } else {
        //     knextImgUrl = this.kiimgurl[kindex - 1];
        //   }
        //   this.currentImgurl = knextImgUrl;
        //   break;
        // case "parlour":
        //   var pindex = this.paimgurl.indexOf(this.currentImgurl);
        //   var pnextImgUrl = "";
        //   if (pindex == 0) {
        //     pnextImgUrl = this.paimgurl[this.paimgurl.length - 1];
        //   } else {
        //     pnextImgUrl = this.paimgurl[pindex - 1];
        //   }
        //   this.currentImgurl = pnextImgUrl;
        //   break;
        // case "balcony":
        //   var bindex = this.baimgurl.indexOf(this.currentImgurl);
        //   var bnextImgUrl = "";
        //   if (bindex == 0) {
        //     bnextImgUrl = this.baimgurl[this.baimgurl.length - 1];
        //   } else {
        //     bnextImgUrl = this.baimgurl[bindex - 1];
        //   }
        //   this.currentImgurl = bnextImgUrl;
        //   break;
        case "field1":
          var findex = this.fiimgurl.indexOf(this.currentImgurl);
          var fnextImgUrl = "";
          if (findex == 0) {
            fnextImgUrl = this.fiimgurl[this.fiimgurl.length - 1];
          } else {
            fnextImgUrl = this.fiimgurl[findex - 1];
          }
          this.currentImgurl = fnextImgUrl;
          break;
        case "standard":
          var sindex = this.stimgurl.indexOf(this.currentImgurl);
          var snextImgUrl = "";
          if (sindex == 0) {
            snextImgUrl = this.stimgurl[this.stimgurl.length - 1];
          } else {
            snextImgUrl = this.stimgurl[sindex - 1];
          }
          this.currentImgurl = snextImgUrl;
          break;
        case "VRImgs":
          var vindex = this.VRImgsUrl.indexOf(this.currentImgurl);
          var vnextImgUrl = "";
          if (vindex == 0) {
            vnextImgUrl = this.VRImgsUrl[this.VRImgsUrl.length - 1];
          } else {
            vnextImgUrl = this.VRImgsUrl[vindex - 1];
          }
          this.currentImgurl = vnextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index == 0) {
            nextImgUrl = this.imgurl[this.imgurl.length - 1];
          } else {
            nextImgUrl = this.imgurl[index - 1];
          }
          this.currentImgurl = nextImgUrl;
          break;
      } 
    },
    nextImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex < this.imgurls.length - 1) {
            anextImgUrl = this.imgurls[aindex + 1];
          } else {
            // anextImgUrl = this.imgurls[aindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            anextImgUrl = this.imgurls[0];
          }
          this.currentImgurl = anextImgUrl;
          break;
        // case "wash":
        //   var windex = this.waimgurl.indexOf(this.currentImgurl);
        //   var wnextImgUrl = "";
        //   if (windex < this.waimgurl.length - 1) {
        //     wnextImgUrl = this.waimgurl[windex + 1];
        //   } else {
        //     wnextImgUrl = this.waimgurl[0];
        //   }
        //   this.currentImgurl = wnextImgUrl;
        //   break;
        // case "kitchen":
        //   var kindex = this.kiimgurl.indexOf(this.currentImgurl);
        //   var knextImgUrl = "";
        //   if (kindex < this.kiimgurl.length - 1) {
        //     knextImgUrl = this.kiimgurl[kindex + 1];
        //   } else {
        //     knextImgUrl = this.kiimgurl[0];
        //   }
        //   this.currentImgurl = knextImgUrl;
        //   break;
        // case "parlour":
        //   var pindex = this.paimgurl.indexOf(this.currentImgurl);
        //   var pnextImgUrl = "";
        //   if (pindex < this.paimgurl.length - 1) {
        //     pnextImgUrl = this.paimgurl[pindex + 1];
        //   } else {
        //     pnextImgUrl = this.paimgurl[0];
        //   }
        //   this.currentImgurl = pnextImgUrl;
        //   break;
        // case "balcony":
        //   var bindex = this.baimgurl.indexOf(this.currentImgurl);
        //   var bnextImgUrl = "";
        //   if (bindex < this.baimgurl.length - 1) {
        //     bnextImgUrl = this.baimgurl[bindex + 1];
        //   } else {
        //     bnextImgUrl = this.baimgurl[0];
        //   }
        //   this.currentImgurl = bnextImgUrl;
        //   break;
        case "standard":
          var sindex = this.stimgurl.indexOf(this.currentImgurl);
          var snextImgUrl = "";
          if (sindex < this.stimgurl.length - 1) {
            snextImgUrl = this.stimgurl[sindex + 1];
          } else {
            snextImgUrl = this.stimgurl[0];
          }
          this.currentImgurl = snextImgUrl;
          break;
        case "field1":
          var findex = this.fiimgurl.indexOf(this.currentImgurl);
          var fnextImgUrl = "";
          if (findex < this.fiimgurl.length - 1) {
            fnextImgUrl = this.fiimgurl[findex + 1];
          } else {
            fnextImgUrl = this.fiimgurl[0];
          }
          this.currentImgurl = fnextImgUrl;
          break;
        case "VRImgs":
        
          var vindex = this.VRImgsUrl.indexOf(this.currentImgurl);
          var vnextImgUrl = "";
          if (vindex < this.VRImgsUrl.length - 1) {
            vnextImgUrl = this.VRImgsUrl[vindex + 1];
          } else {
            
            vnextImgUrl = this.VRImgsUrl[0];
          }
          this.currentImgurl = vnextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index < this.imgurl.length - 1) {
            nextImgUrl = this.imgurl[index + 1];
          } else {
            nextImgUrl = this.imgurl[0];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    close(){
      this.imgviewmodal=false;
      this.actimgviewmodal=false;
    },
    viewOriginImg(){
      window.open(this.currentImgurl);
    },
    viewActOriginImg(){
      window.open(this.currentActImgurl);
    },
    //旋转
    // revolve(elename) {
    //   this.xuanzhuanNumCar = this.xuanzhuanNumCar + 1;
    //   var box = document.getElementById(elename);
    //   box.style.transform = 'rotateZ(' + 90 * this.xuanzhuanNumCar + 'deg)';
    // },
    //提交审核
    // Submit() {
    //   var url = "/Customer/DoAuditPass";
    //   var that = this;
    //   this.submitloading = true;
    //   this.$http
    //     .get(url, { params: { id: this.$route.params.id } })
    //     .then(res => {
    //       that.submitloading = false;
    //       var content = "";
    //       if (res.data == "success") {
    //         content = "操作成功";
    //       } else {
    //         content = res.data;
    //       }
    //       this.$Modal.info({ title: "提示", content: content });
    //       that.GoBack();
    //     });
    // },
    NotPass(){
      var url = "/Customer/DoAuditNotPass";
      var that = this;
      this.submitloading = true;
      this.$http
        .get(url, { params: { id: this.$route.params.id } })
        .then(res => {
          that.submitloading = false;
          var content = "";
          if (res.data == "success") {
            content = "操作成功";
          } else {
            content = "系统异常";
          }
          this.$Modal.info({ title: "提示", content: content });
          this.$router.push({ name: "dealer_waitauditcustomerlist" });
          // that.GoBack();
        });
    },
    // 管路图上传合格
    PipeCheck(ischeck) {
        var url = "/Customer/DoCheck";
        var that = this;
        this.submitloading = true;
        this.$http
          .get(url, { params: { id: this.$route.params.id, isPipeCheck: ischeck} })
          .then(res => {
            that.submitloading = false;
            var content = "";
            if (res.data == "success") {
              content = "操作成功";
            } else {
              content = "系统异常";
            }
            this.$Modal.info({ title: "提示", content: content });
            this.reload();
            this.GetCompanyCheck();
            //that.GoBack();
          });
    },
    // 管路图上传不合格
    PipeUnCheck() {
      this.unreasonmodal.customerid = this.$route.params.id;
      this.unreasonmodal.show = true;
    },
    // 管路图审核通过
    DoPass() {
      this.auditmodal.customerid = this.$route.params.id;
      this.auditmodal.show = true;
    },
    // 不合格确认操作
    UnReason_ModalOK: function() {
      var that = this;
      if (that.unreasonmodal.unreason.length <= 0) {
        that.$Modal.warning({
          title: "提示",
          content: "请填写不合格原因！"
        });
        return false;
        // that.$Message.warning("请填写不合格原因！");
        // return false;
      }
      that.$http
        .get("/Customer/DoUnCheck", {
          params: {
            unreason: that.unreasonmodal.unreason,
            id: that.unreasonmodal.customerid,
            isPipeCheck: -1
          }
        })
        .then(res => {
          var content = "";
            if (res.data == "success") {
              content = "操作成功";
            } else {
              content = "系统异常";
            }
            that.$Modal.info({ title: "提示", content: content });
            that.reload();
            that.GetCompanyCheck();
            that.unreasonmodal.unreason="";
        });
    },
    // 审核通过确认操作
    AuditRemark_ModalOK: function() {
      var that = this;
      if (that.auditmodal.auditremark.length <= 0) {
        that.$Modal.warning({
          title: "提示",
          content: "请填写审核通过备注信息！"
        });
        return false;
      }
      var url = "/Customer/DoAuditPass";
      that.submitloading = true;
      that.$http
        .get(url, { 
          params: { 
            id: that.auditmodal.customerid, 
            auditremark: that.auditmodal.auditremark,
          } 
        }).then(res => {
          that.submitloading = false;
          var content = "";
          if (res.data == "success") {
            content = "操作成功";
          } else {
            content = res.data;
          }
          that.$Modal.info({ title: "提示", content: content });
          that.$router.push({ name: "dealer_waitauditcustomerlist" });
          // that.GoBack();
        });
    },
    WarnAuditLogList: function(e) {
      this.$router.push({
        name: "dealer_warnauditlog",
        params: { workerid: e.WorkerId, sdworkertel: e.SDWorkerTel}
      });
    },
    GetData() {
      this.$http
        .get("/Customer/GetCustomer", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          var that = this;
          this.dataobj = res.data.Data;
          // 查询管路图是否合格，未设置则按钮可用，已设置则按钮不可用
          that.ischecks = res.data.Data.IsPipeCheck;
          if(that.ischecks == 0){
            that.dis = false;
          }else{
            that.dis = true;
          }
          if (res.data.Data.Imgs != null && res.data.Data.Imgs.length > 0) {
            var imgStr = res.data.Data.Imgs;
            var imgarray = imgStr.split(",");
            // var imgUrlArray = [];
            var otherProductArray = res.data.Data.OtherProduct.split(",");
            if (otherProductArray.length >= 3) {
              res.data.Data.OtherProduct = 40;
            } else {
              res.data.Data.OtherProduct = 0;
            }
            var imgUrlArray = imgarray.map(item => {
              if (item.search("Images") != -1) {
                //老数据的图片路径
                return that.base_url + item;
              } else if (item.search("Images") == -1) {
                //新路径格式 2018年9月10日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.Data.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
              }
            });
            that.imgurl = imgUrlArray;
          } else {
            that.imgurl = [];
          }
          if (res.data.Data.ActImgs != null && res.data.Data.ActImgs.length > 0) {
            var aimgStr = res.data.Data.ActImgs;
            var aimgarray = aimgStr.split(",");
            // var actImgUrlArray = [];
            
            var actImgUrlArray = aimgarray.map(item => {
              //路径格式 2020年9月10日
              return (
                  that.base_url +
                  "Images/activity/" +
                  res.data.Data.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
            });
            that.actimgurl = actImgUrlArray;
          } else {
            that.actimgurl = [];
          }
        });

    },
    GetDataNew(url) {
      this.$http
        .get("/Customer/GetCustomerPic", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          var that = this;
          this.dataobj = res.data.Data.customer;
          // 查询管路图是否合格，未设置则按钮可用，已设置则按钮不可用
          that.ischecks = res.data.Data.customer.IsPipeCheck;
          if(that.ischecks == 0){
            that.dis = false;
          }else{
            that.dis = true;
          }
          // 判断是否为电子质保卡
          var warrantytype = res.data.Data.customer.WarrantyType.split(',');
          var iswarranty = res.data.Data.customer.IsWebWarrantyNum;
          if(iswarranty == 1 && warrantytype.length == 2){
            this.dataobj.flag = true;
          }else if(iswarranty == 1 && warrantytype.length == 1){
            this.dataobj.flag = true;
          }else{
            this.dataobj.flag = false;
          }
          
          switch (url) {
            case "all":
              that.imgurls=[];
         
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.Field1 != null && res.data.Data.customerex.Field1.length > 0) {
                var afimgStr = res.data.Data.customerex.Field1;
                var afimgarray = afimgStr.split(",");
                var afimgUrlArray = [];
                var afimgUrlArray = afimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "field1" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.fiimgurl = afimgUrlArray;
                that.imgurls = that.imgurls.concat(that.fiimgurl);
              }
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var aimgStr = res.data.Data.customer.Imgs;
                var aimgarray = aimgStr.split(",");
                // var aimgUrlArray = [];
                var aimgUrlArray = aimgarray.map(item => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = aimgUrlArray;
                that.imgurls = that.imgurls.concat(that.imgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.WarrantyImgs != null && res.data.Data.customerex.WarrantyImgs.length > 0) {
                var awrimgStr = res.data.Data.customerex.WarrantyImgs;
                var awrimgarray = awrimgStr.split(",");
                var awrimgUrlArray = [];
                var awrimgUrlArray = awrimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "warranty" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.wrimgurl = awrimgUrlArray;
                that.imgurls = that.imgurls.concat(that.wrimgurl);
              }
              // if (res.data.Data.customerex!=null&&res.data.Data.customerex.SignImgs != null && res.data.Data.customerex.SignImgs.length > 0) {
              //   var signImgStr = res.data.Data.customerex.SignImgs;
              //   var signImgarray = signImgStr.split(",");
              //   var signimgUrlArray = [];
              //   var signimgUrlArray = signImgarray.map(item => {
              //     //新路径格式 2018年9月10日
              //       return (
              //         that.base_url +
              //         "Images/upload/" +
              //         res.data.Data.customer.WarrantyNum +
              //         "/" +
              //         "warranty/sign" +
              //         "/" +
              //         item +
              //         ".jpg"
              //       );
              //   });
              //   that.signimgurl = signimgUrlArray;
              //   that.imgurls = that.imgurls.concat(that.signimgurl);
              // }
              break;
            case "standard":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.StandardImgs != null && res.data.Data.customerex.StandardImgs.length > 0) {
                var simgStr = res.data.Data.customerex.StandardImgs;
                var simgarray = simgStr.split(",");
                // var simgUrlArray = [];
                var simgUrlArray = simgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.stimgurl = simgUrlArray;
              } else {
                that.stimgurl = [];
              }
              break;
            case "VRImgs":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.VRImgs != null && res.data.Data.customerex.VRImgs.length > 0) {
                var vimgStr = res.data.Data.customerex.VRImgs;
                var vimgarray = vimgStr.split(",");
                // var vimgUrlArray = [];
                var vimgUrlArray = vimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.VRImgsUrl = vimgUrlArray;
              } else {
                that.VRImgsUrl = [];
              }
              // if (res.data.Data.customerex!=null&&res.data.Data.customerex.StandardDesc != null) {
              //   that.StandardDesc = res.data.Data.customerex.StandardDesc;
              // }
              break;
            case "billimgs":
              if(res.data.Data.presaleEx!=null&&res.data.Data.presaleEx.BillImgs!=null){
                that.billway=2;
              }else{
                that.billway=1;
              }
              if (res.data.Data.presaleEx!=null&&res.data.Data.presaleEx.BillImgs != null && res.data.Data.presaleEx.BillImgs.length > 0) {
                var biimgStr = res.data.Data.presaleEx.BillImgs;
                var biimgarray = biimgStr.split(",");
                // var biimgUrlArray = [];
                var biimgUrlArray = biimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "BillImgs/" +
                      res.data.Data.presaleEx.BillCode +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.BillImgsUrl = biimgUrlArray;
              } else {
                that.BillImgsUrl = [];
              }
              that.PreSaleEX = res.data.Data.presaleEx;
              that.MaterialList = res.data.Data.matList;
              that.MaterialList.forEach((item, idx, array) => {           
                array[idx].ImgUrl =
                  that.$file.url +
                  "Images/SDWorkerMaterial/" +
                  item.MaterialID +
                  "/" +
                  item.ImgUrl;
              });
              break;
            case "field1":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.Field1 != null && res.data.Data.customerex.Field1.length > 0) {
                var fimgStr = res.data.Data.customerex.Field1;
                var fimgarray = fimgStr.split(",");
                // var pimgUrlArray = [];
                var fimgUrlArray = fimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.fiimgurl = fimgUrlArray;
              } else {
                that.fiimgurl = [];
              }
              break;
            default:
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var imgStr = res.data.Data.customer.Imgs;
                var imgarray = imgStr.split(",");
                // var imgUrlArray = [];
                var otherProductArray = res.data.Data.customer.OtherProduct.split(",");
                if (otherProductArray.length >= 3) {
                  res.data.Data.customer.OtherProduct = 40;
                } else {
                  res.data.Data.customer.OtherProduct = 0;
                }
                var imgUrlArray = imgarray.map(item => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = imgUrlArray;
              } else {
                that.imgurl = [];
              }
              break;
          }
          // 活动图片
          if (res.data.Data.customer.ActImgs != null && res.data.Data.customer.ActImgs.length > 0) {
            var actimgStr = res.data.Data.customer.ActImgs;
            var actimgarray = actimgStr.split(",");
            // var actImgUrlArray = [];
            
            var actImgUrlArray = actimgarray.map(item => {
              //路径格式 2020年9月10日
              return (
                  that.base_url +
                  "Images/activity/" +
                  res.data.Data.customer.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
            });
            that.actimgurl = actImgUrlArray;
          } else {
            that.actimgurl = [];
          }
          // 签名图片
          if (res.data.Data.customerex!=null&&res.data.Data.customerex.SignImgs != null && res.data.Data.customerex.SignImgs.length > 0) {
              var siimgStr = res.data.Data.customerex.SignImgs;
              var siimgarray = siimgStr.split(",");
              // var pimgUrlArray = [];
              var siimgUrlArray = siimgarray.map(item => {
                //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/sign/" +
                    item +
                    ".jpg"
                  );
              });
              that.siimgurl = siimgUrlArray;
            } else {
              that.siimgurl = [];
            }
        });
      
    },
    GetIntegralInfo() {
      this.$http
        .get("/Customer/GetIntegralInfo", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          this.integralobj = res.data.Data;
        });
    },
    // CheckSame() {
    //   this.$http
    //     .get("/Customer/CheckSame", {
    //       params: {
    //         id: this.$route.params.id
    //       }
    //     })
    //     .then(res => {
    //       if(res.data!="success"){
    //         // alert(res.data);//强提示 
    //         this.$Modal.info({ title: "提示", content: res.data });           
    //       }
    //   });
    // },
    CheckSame() {
      this.$http
        .get("/Customer/CheckSame", {
          params: {
            id: this.$route.params.id
          }
        }).then(res => {
          if(res.data.Msg=="success"){
            this.wcount=res.data.Data.WorkerCount;
            this.acount=res.data.Data.AddressCount;
            this.scount=res.data.Data.SDWorkerCount;
            this.ccount=res.data.Data.CompanyCount;
            this.dcount=res.data.Data.DealerCount;
            var content = "";
            if(this.wcount>=1){
              content="该水工试压记录共"+this.scount+"次，与此试压员有"+this.wcount+"次重复试压记录，<br>";
            }
            if(this.acount>=1){
              content=content+"<div style='color:red;'>该地址有"+this.acount+"次试压记录，<br></div>";
            }
            if(this.dcount>=2&&this.ccount<2){
              content=content+"<div style='color:red;'>该经销商录单有"+this.dcount+"次不一致记录，<br></div>";
            }
            if(this.dcount>=2&&this.ccount>=2){
              content=content+"<div style='color:red;'>该经销商录单有"+this.dcount+"次不一致记录，装饰公司有"+this.ccount+"次不一致记录，<br></div>";
            }
            // if(this.ccount>=2){
            //   content=content+"<div style='color:red;'>该经销商录单家装公司有"+this.ccount+"次不一致记录，<br></div>";
            // }
            if(content!=""){
              content=content+"请核实情况!"
              this.$Modal.info({ title: "提示", content: content });
            }         
          }
      });
    },
    handleClick: function(e) {
      this.active=e;
      this.GetDataNew(e);
    },
    handle2Click: function(e) {
      this.active2=e;
    },
    ModalCancel: function() {
      console.log("取消");
      this.$http.SetConditions();
    },
    GoBack: function() {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    watchKey(e) {
      var keyNum = window.event ? e.keyCode : e.which; //获取被按下的键值
      if (keyNum == 39) {
        if(this.agentId == 1){
          this.nextImgNew();
        }else{
          this.nextImg();
        }
        // this.nextImg();
        //按下回车按钮要做的事;
      } else if (keyNum == 37) {
        if(this.agentId == 1){
          this.preImgNew();
        }else{
          this.preImg();
        }
        // this.preImg();
      }
    },
    onChange:function (event) {
      this.activeNames = event.detail;
    },
    SubmitData: function() {
      var that = this;
      
      var data = {
        CustomerID: that.postdata.CustomerID,
        IsCompanySame: that.postdata.IsCompanySame,
        Checker: that.postdata.Checker
      };
      if(!this.CheckData(data)){
        return ;
      }

      that.$http.post("companycheck/AddCheck", data)
        .then(res => {
        var msg = "";
        if (res.data != "success") {
          msg = res.data;
        } else {
          msg = "提交成功！";
          this.GetCompanyCheck();
        }
        that.$Modal.info({
          title: "提示",
          content: msg
        });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.IsCompanySame == "") {
        content = "请选择经销商录单家装公司是否与现场一致";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    GetCompanyCheck() {
      this.$http
        .get("/companycheck/GetCompanyCheck", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          this.isCheck = res.data;
        });
    },
  },
  created() {
    this.agentId = this.$route.params.agentId;
    if(this.agentId == 1){
      this.CheckSame();
      this.GetDataNew(this.active);
      this.GetIntegralInfo();
      this.GetCompanyCheck();
    }else if(this.agentId == 5){
      this.GetDataNew(this.active);
      this.GetIntegralInfo();
    }else{
      this.GetData();
      this.GetIntegralInfo();
    }
    this.postdata.CustomerID=this.$route.params.id;
    
    // this.GetData();
    var that = this;
    document.addEventListener("keydown", that.watchKey);
  },
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
  },
};
</script>

<style>
</style>
